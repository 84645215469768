.cc-logo{
  margin-top: 16px;
  img{
    opacity: 1;
    height: 48px;
    margin: 4px;
    &.idle{
      opacity: .5;
    }
  }
}