.payment-method{
  background: #f6f6e2;
  border: thin dashed #d4d4bc;
  padding: 8px;
  img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 24px;
  }
}