@import '~libs/react-mpk/styles/var';

.payment-status{
  background: #f6f6e2;
  padding: 24px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
  .icon{
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 4px solid $mpk-color-progress;
    margin: 8px auto 24px auto;
    .md-icon{
      font-size: 60px;
      color: $mpk-color-progress;
    }
  }
  &.error{
    .icon{
      border: 4px solid $mpk-color-warn;
      .md-icon{
        color: $mpk-color-warn
      }
    }
  }
}