@import 'globals';
@import 'libs/react-mpk/styles/mpk';
@import url('https://fonts.googleapis.com/css?family=Comfortaa:400,500,600');

$max-media-width:1024px;
$max-side-width:240px;

.App {
  width: 100%;
  height: 100%;
}

.center-container{
  width: $max-media-width;
  margin:0 auto;
}

.side-container{
  width: $max-side-width;
}

.form-container{
  margin:0 auto;
  &.md {max-width: 800px;}
  &.sm {max-width: 480px;}
}

.text-editor{
  border:thin solid $mpk-border-color-dark;
  padding:0 $mpk-padding-N;
}

.md-divider--text-field{
  &:after {
    height: 1px !important;
  }
}

.tabs-details{
  border-bottom:thin solid $mpk-border-color-dark;
  background: #f8f8f8;
  padding-left: 0px !important;
}
.md-tab{
  min-width: 0px !important;
}
.md-tab--inactive{
  color:rgba(0,0,0,.52) !important;
}
.md-edit-dialog__label{
  width:auto !important;
}
.full-form{
  width: 100% !important;
}

.min-width-S{width: 92px;}
.min-width-N{width: 160px;}
.min-width-L{width: 240px;}

.bg-pattern{
  display: none;
}

@media screen and (max-width: $max-media-width){
  .center-container{
    width: 100%;
    padding:0 $mpk-padding-N
  }
}


.sp{
  .header{
    background: #f5f7f3;
  }
  *:not(.md-icon){ 
    font-family: 'Comfortaa', sans-serif !important
  }

  .mpk-appbar, .md-background--primary, .md-switch-thumb--on, .md-tab-indicator{
    background: #689f38 !important;
  }

  .mpk-sidabar-menu .group .menu-item.active .md-icon,
  .md-text--theme-primary, .md-tab--active, .mpk-font-color-primary{
    color: #689f38 !important;
  }

  .md-background--secondary{
    background: #1b5e20 !important;
  }

  .mpk-divider-label{
    color: #1b5e20 !important;
  }

  .mpk-divider-label{
    border-color: #1b5e20 !important;
  }

  .md-switch-track--on{ 
    background: #689f38 !important;
  }

  .info .pjk *{
    display: none;
  }

  .bg{display: none}
  .bg-pattern{
    display: block !important;
  }
  .account{
    background: transparent !important;
  }

  .logo-container{
    display: flex !important;
    align-items: center !important;
  }
  .logo-tag{
    position: relative !important;
    bottom: unset !important;
    right: unset !important;
    font-style: normal !important;
    color: #aaa !important;
    margin-left: 4px;
  }
}

.markdown{
  li{
    padding-left: 16px;
  }

  p{
    margin-bottom: 24px !important;
  }
  strong{
    font-weight: 600;
  }
}