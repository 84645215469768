$color: #5555b3;
.mpk-account-card{
  width: 320px;
  height: 180px;
  border: 4px solid $color;
  border-radius: 8px;
  margin: 16px auto;
  .bold-line{
    background-color: $color;
    width: 100%;
    height: 48px;
    margin-bottom: 8px;
  }
  .info-container{
    color:$color;
    padding: 4px 16px;
  }
  .card-pan{
    font-size: 32px;
    border: 2px solid $color;
    padding: 4px 8px;
    border-radius: 8px;
    // background: $color;
    // color: white;
  }
  .phone{
    font-size: 12px;
  }
  .email{
    font-weight: 800;
    word-wrap: break-word;
  }
}