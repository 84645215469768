
.mpk-rate-plan-card-img{
    width: 100%;
    height: 180px;
    .default{
        position: relative;
        width: 100%;
        height: 100%;
        background: #5d6570;
        .md-icon{
            font-size: 72px;
            color: #aaa;
        }
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;    
    }
}