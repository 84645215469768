.mpk-table {
  position: relative; }
  .mpk-table th .resizer {
    position: absolute;
    height: 100%;
    cursor: col-resize;
    left: 0;
    top: 0; }
    .mpk-table th .resizer .md-icon {
      font-size: 12px !important;
      padding-right: 8px; }
  .mpk-table p {
    margin: 0px; }
  .mpk-table .mpk-link p {
    color: #1e88e5; }
  .mpk-table .status {
    display: inline-block; }
    .mpk-table .status.boolean {
      min-width: 48px; }
      .mpk-table .status.boolean div {
        text-align: center; }
  .mpk-table .md-data-table--responsive {
    height: 100% !important; }
  .mpk-table .md-data-table--responsive.md-data-table--fixed {
    width: 100%; }
  .mpk-table .md-data-table__fixed-wrapper {
    width: 100%; }
  .mpk-table .md-table-column {
    white-space: unset;
    min-height: 120px; }
  .mpk-table .md-icon-text:last-child {
    padding-left: 4px; }
  .mpk-table .md-table-column--header .md-icon {
    font-size: 12px !important; }
  .mpk-table #mpk-inner-tbl {
    overflow-x: auto;
    overflow-y: hidden; }
  .mpk-table .query-info {
    position: absolute;
    width: 100%;
    height: 100%;
    /*.not-found {
      height:100%;
      img {
        height: 50%;
        margin-right: 24px;
      }
      .info{
        max-width: 25%;
        div:first-child{
          color:$mpk-color-warn;
        }
        .todo{
          margin-top:4px;
        }
      }
    }*/ }
  .mpk-table .header-loader {
    height: 56px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px; }
  .mpk-table .md-tile-addon {
    line-height: 1 !important; }
  .mpk-table .md-tile-content--left-icon {
    padding-left: 16px !important; }
  .mpk-table .md-icon.mdi {
    font-size: 20px; }
  .mpk-table .md-table-column__fixed.md-table-column__fixed--header.sortable {
    cursor: pointer; }
  .mpk-table .md-data-table--responsive.md-data-table--fixed {
    display: grid; }
  .mpk-table .md-data-table__fixed-wrapper {
    min-width: unset; }
  .mpk-table .md-progress--linear {
    margin: 0;
    position: absolute;
    top: 56px;
    height: 2px;
    background: #f2f2f2; }
    .mpk-table .md-progress--linear.show {
      opacity: 1;
      animation-name: fade-in;
      animation-duration: 1s; }
    .mpk-table .md-progress--linear.hide {
      opacity: 0;
      animation-name: fade-out;
      animation-duration: 1s; }

.mpk-table-setting {
  width: 240px; }
  .mpk-table-setting .md-tab {
    min-width: 50%; }
  .mpk-table-setting .md-tab-indicator {
    width: 50% !important; }
  .mpk-table-setting .md-tab--inactive {
    color: rgba(0, 0, 0, 0.72) !important; }

/*.md-data-table__scroll-wrapper{
  height:auto !important;
}*/
@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@media screen and (max-width: 960px) {
  .mpk-table .query-info .not-found img {
    height: auto;
    width: 50%;
    margin-right: 0;
    margin-bottom: 24px; }
  .mpk-table .query-info .not-found .info {
    max-width: 80%;
    text-align: center; } }

@media screen and (max-width: 600px) {
  .mpk-table-setting {
    width: 100%; } }
